<template>
  <button
    type="button"
    :class="[
      'AsomButton',
      outline ? `AsomButton--variant-outline-${variant}` : `AsomButton--variant-${variant}`,
      `AsomButton--size-${size}`,
      (loading || disabled) && `AsomButton--is-disabled`,
      ((icon && !text) || (loading && !loadingText)) && `AsomButton--has-icon-only`,
      `rounded-${rounded}`
    ]"
    @click="onClick"
  >
    <span class="AsomButton__Text" v-if="!loading">
      <asom-icon v-if="icon && iconPosition === 'left'" :icon="icon" class="AsomButton__Icon" size="sm" />
      <span v-dompurify-html="text" v-if="!!text"></span>
      <asom-icon v-if="icon && iconPosition === 'right'" :icon="icon" class="AsomButton__Icon" size="sm" />
    </span>
    <span class="AsomButton__Loading" v-else>
      <asom-icon class="AsomButton__LoadingIcon" icon="spinner" size="sm" />
      <span v-if="loadingText" class="AsomButton__LoadingText">{{loadingText}}</span>
    </span>
  </button>
</template>
<script>
import AsomIcon from '@/components/icon/AsomIcon'
import ICON_SET from '@/constants/ICON_SET'

export default {
  name: "AsomButton",
  components: { AsomIcon },
  emits: [ 'click' ],
  props: {
    variant: {
      type: String,
      default: 'primary',
      validate: (value) => [
        'primary', 'secondary', 'success', 'error', 'warning', 'link', 'cancel'
      ].includes(value),
    },
    outline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      validate: (value) => [
        'xs', 'sm', 'md', 'lg', 'xl', 'full'
      ].includes(value),
    },
    icon: {
      type: String,
      validate: (value) => Object.keys(ICON_SET).includes(value),
    },
    iconPosition: {
      type: String,
      default: 'left',
      validate: (value) => [ 'left', 'right' ].includes(value)
    },
    text: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: 'md'
    },
  },
  methods: {
    onClick(e) {
      if (!this.disabled) {
        this.$emit('click', e)
      }
    }
  }
}
</script>
<style scoped>
.AsomButton {
  @apply inline-flex justify-center items-center border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 font-medium;
}

.AsomButton--variant-primary {
  @apply text-white bg-button border border-transparent shadow-sm hover:bg-button-hover
}

.AsomButton--variant-secondary {
  @apply bg-gray-200 text-gray-600 border border-transparent shadow-sm hover:text-gray-700 hover:bg-gray-300
}

.AsomButton--variant-error {
  @apply text-white bg-red-500 border border-transparent shadow-sm hover:bg-red-600
}

.AsomButton--variant-success {
  @apply text-white bg-green-500 border border-transparent shadow-sm hover:bg-green-600
}

.AsomButton--variant-warning {
  @apply text-white bg-yellow-500 border border-transparent shadow-sm hover:bg-yellow-600
}

.AsomButton--variant-link {
  @apply text-blue-600 underline hover:text-blue-600 shadow-none focus:ring-0 focus:ring-offset-0
}

.AsomButton--variant-outline-primary {
  @apply text-blue-600 bg-white border border-button shadow-sm hover:bg-button hover:text-white
}

.AsomButton--variant-outline-secondary {
  @apply text-gray-700 bg-white border border-gray-700 shadow-sm hover:text-white hover:bg-gray-700
}

.AsomButton--variant-outline-error {
  @apply text-red-600 bg-white border border-red-500 shadow-sm hover:bg-red-500 hover:text-white
}

.AsomButton--variant-outline-success {
  @apply text-green-600 bg-white border border-green-500 shadow-sm hover:bg-green-500 hover:text-white
}

.AsomButton--variant-outline-warning {
  @apply text-yellow-600 bg-white border border-yellow-500 shadow-sm hover:bg-yellow-500 hover:text-white
}

.AsomButton--variant-outline-cancel {
  @apply text-gray-700 bg-white border border-gray-300 shadow-sm hover:text-gray-500
}

.AsomButton--is-disabled {
  @apply text-white bg-gray-400 hover:bg-gray-400 cursor-not-allowed
}

.AsomButton__Text, .AsomButton__Loading {
  @apply inline-flex space-x-2
}

.AsomButton__LoadingIcon {
  @apply animate-spin
}

.AsomButton--size-xs {
  @apply px-2.5 py-1.5 text-xs rounded
}
.AsomButton--size-sm {
  @apply px-3 py-2 text-sm leading-4 
}
.AsomButton--size-md {
  @apply px-4 py-2 text-sm 
}
.AsomButton--size-lg {
  @apply px-4 py-2 text-base 
}
.AsomButton--size-xl {
  @apply px-6 py-3 text-base 
}
.AsomButton--size-full {
  @apply px-4 py-2 text-base  w-full flex justify-center
}

.AsomButton--has-icon-only {
  @apply px-2.5
}
</style>