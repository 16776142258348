<template>
  <nav class="flex asom-breadcrumb" aria-label="Breadcrumb">
    <ol class="bg-white rounded-md shadow px-6 flex space-x-4">
      <template v-for="(link, i) in links" :key="i">
        <li v-if="i === 0" class="flex">
          <div class="flex items-center">
            <a :href="link.href" class="text-gray-400 hover:text-gray-500">
              <svg
                class="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              <span class="sr-only">{{ link.text }}</span>
            </a>
          </div>
        </li>
        <li v-else class="flex" :class="{ active: i == links.length - 1 }">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 w-6 h-full text-gray-200"
              viewBox="0 0 24 44"
              preserveAspectRatio="none"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <a
              v-if="!(i == links.length - 1)"
              :href="link.href"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >{{ link.text }}</a
            >
            <a
              v-else
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            >
              {{ link.text }}
            </a>
          </div>
        </li>
      </template>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "AsomBreadcrumb",
  props: {
    links: {
      type: Array,
      required: true,
      validate: (arr) =>
        arr.every(
          (item) =>
            !!item &&
            typeof item.href === "string" &&
            typeof item.text === "string"
        ),
    },
  },
};
</script>

<style lang="scss">
.AsomBreadcrumb__List {
  @apply flex items-end justify-start py-4 space-x-2;
}

.AsomBreadcrumb__Item {
  @apply flex items-center text-center;
}

.AsomBreadcrumb__Item .AsomBreadcrumb__ItemLink {
  @apply visited:text-blue-500;
}

.AsomBreadcrumb__ItemIcon {
  @apply block;
}

.AsomBreadcrumb {
  @apply flex mt-5;
}
.AsomBreadcrumb__Link--item {
  @apply flex items-center;
}
.AsomBreadcrumb_Link--current {
  @apply ml-2 text-sm font-medium text-gray-700 font-semibold;
}
.AsomBreadcrumb__Arrow {
  @apply flex-shrink-0 h-5 w-5 text-gray-400;
}
.AsomBreadcrumb_Link {
  @apply ml-2 text-sm font-medium text-gray-500 hover:text-indigo-500 font-semibold hover:underline;
}

.asom-breadcrumb {
  li.active {
    a {
      color: theme("colors.gray.900");
    }
  }
}
</style>
