<template>
  <span :class="[ 'AsomBadge', `AsomBadge--variant-${variant}`]">
    <slot></slot>
  </span>
</template>
<script>
export default {
  name: 'AsomBadge',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validate: (value) => [ 'primary', 'secondary', 'success', 'error', 'warning', 'info', 'light', 'dark' ].includes(value),
    },
  }
}
</script>
<style scoped>
.AsomBadge {
  @apply px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-lg bg-gray-100 text-gray-900
}

.AsomBadge--variant-primary {
  @apply bg-blue-500 text-white
}

.AsomBadge--variant-secondary {
  @apply bg-gray-500 text-white
}

.AsomBadge--variant-success {
  @apply bg-green-500 text-white
}

.AsomBadge--variant-error {
  @apply bg-red-500 text-white
}

.AsomBadge--variant-warning {
  @apply bg-yellow-500 text-gray-900
}

.AsomBadge--variant-info {
  @apply bg-blue-400 text-gray-900
}

.AsomBadge--variant-light {
  @apply bg-gray-100 text-gray-900
}

.AsomBadge--variant-dark {
  @apply bg-black text-white
}
</style>