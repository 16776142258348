<template>
  <div :class="['AsomCard shadow', `AsomCard--size-${size}`]">
    <div class="AsomCard__Header" v-if="title">
      <slot name="header">
        <h3 class="AsomCard__HeaderTitle">{{ title }}</h3>
      </slot>
    </div>
    
    <asom-icon 
      v-if="dismissible"
      @click="$emit('dismiss')"
      class="AsomCard__DismissButton" icon="close"></asom-icon>
    <div class="AsomCard__Body">
      <slot></slot>
    </div>
    <div class="AsomCard__Footer space-x-5">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
import AsomIcon from '../icon/AsomIcon.vue';

export default {
  name: "AsomCard",
  components: {
    AsomIcon,
  },
  emits: [ 'dismiss' ],
  props: {
    title: {
      type: String,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "full",
      validate: (value) =>
        ["sm", "md", "lg", "xl", "2xl", "full"].includes(value),
    },
  },
};
</script>
<style scoped>
.AsomCard {
  @apply relative rounded-lg shadow divide-y divide-gray-200;
}

.AsomCard--size-sm {
  @apply max-w-sm;
}

.AsomCard--size-md {
  @apply max-w-md;
}

.AsomCard--size-lg {
  @apply max-w-lg;
}

.AsomCard--size-xl {
  @apply max-w-xl;
}

.AsomCard--size-2xl {
  @apply max-w-2xl;
}

.AsomCard--size-full {
  @apply max-w-full;
}

.AsomCard div:first-child {
  @apply rounded-t-lg
}

.AsomCard div:last-child {
  @apply rounded-b-lg
}

.AsomCard__Header {
  @apply bg-white px-4 py-5  sm:px-6;
}

.AsomCard__HeaderTitle {
  @apply text-lg leading-6 font-medium text-gray-900;
}

.AsomCard__Body {
  @apply text-left px-6 pt-4 pb-6 bg-white;
}
.AsomCard__Footer {
  @apply px-6 py-3 bg-gray-50 flex space-x-2 justify-end;
}

.AsomCard__Footer:empty {
  @apply hidden;
}

.AsomCard__DismissButton {
  @apply absolute top-2 right-2 cursor-pointer;
}
</style>
