<template>
  <div class="pt-2">
    <slot :openScanner="openScanner">
      <asom-button
        outline
        size="sm"
        :text="text"
        icon="qr"
        @click="openScanner"
      />
    </slot>
    <teleport to="body">
      <div v-if="showScanner" :class="['AsomBarCodeScanner']">
        <div id="asoms-barcode-scanner-container" class="viewport scanner">
          <button class="close" @click="closeScanner()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <div id="asoms-barcode-scanner" class="w-full h-full" />
        </div>
      </div>
    </teleport>
  </div>
</template>
<script>
import scannerMixin from '../scanner.mixin';

export default {
  name: "AsomBarCodeScanner",
  props: {
    text: {
      type: String,
      default: "Scan Barcode",
    },
  },
  mixins: [ scannerMixin ],
  computed: {
    scannerId() {
      return "asoms-barcode-scanner";
    }
  },
  methods: {
    async closeExistingCameraUsage() {
      if (document) {
        let video = document.querySelector(".AsomBarCodeScanner video");
        console.log(video)
        if (video != null) {
          console.log("destroy video")
          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');
        }
      }
      if (navigator && navigator.mediaDevices) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({audio: false, video: true});
        console.log(mediaStream);
        mediaStream.getTracks().forEach(track => track.stop());
      }
    },
  }
};
</script>

<style scoped>
.AsomBarCodeScanner {
  @apply w-screen h-screen fixed block top-0 left-0 bg-white;
  z-index: 1000;
}
.AsomBarCodeScanner--is-hidden {
  @apply hidden;
}

.close {
  z-index: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  border: none;
  outline: none;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

#asoms-barcode-scanner-container {
  height: 100%;
}

#asoms-barcode-scanner {
  height: 100%;
  background-color: black;
  display: flex;
}
</style>
