<template>
  <div :class="[ !fluid ? 'AsomContainer' : 'AsomContainer--fluid' ]">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'AsomContainer',
  props: {
    fluid: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
<style scoped>
.AsomContainer {
 @apply container mx-auto px-4
}

.AsomContainer--fluid {
  @apply px-4
}
</style>