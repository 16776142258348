<template>
  <button
    type="button"
    @click="onExcelExportClicked"
    class="flex justify-center items-center text-center px-4 py-2 text-sm hover:bg-gray-100 flex-shrink-0">
    <svg class="flex-shrink-0 h-3.5 w-3.5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
    </svg>
    <span>Export Excel</span>
  </button>
</template>

<script>
import { exportExcel } from "@/helpers/excel";

export default {
  name: "AsomExportToExcel",
  props: {
    fileName: {
      type: String,
      default: "Report"
    },
    sheetName: {
      type: String,
      default: "report"
    },
    fields: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    onExcelExportClicked() {
      exportExcel(
        this.fileName,
        this.sheetName,
        this.fields,
        this.labels,
        this.data
      )
    },
  },
}
</script>