<template>
  <div
    v-if="iconSvg"
    :class="[
      'AsomIcon',
      `AsomIcon--size-${size}`
    ]"
    v-dompurify-html:svg="iconSvg"
  >
  </div>
</template>
<script>
import DOMPurify from 'dompurify'
import ICON_SET from '@/constants/ICON_SET'

export default {
  name: "AsomIcon",
  props: {
    icon: {
      type: String,
      required: true,
      validate: (value) => Object.keys(ICON_SET).includes(value),
    },
    size: {
      type: String,
      default: 'md',
      validate: (value) => ['xs', 'sm', 'md', 'lg'].includes(value)
    }
  },
  computed: {
    iconSize() {
      switch (this.size) {
        case 'xs':
          return 10;
        case 'sm':
          return 20;
        case 'lg':
          return 48;
        default:
          return 24;
      }
    },
    iconSvg() {
      return DOMPurify.sanitize(ICON_SET[this.icon])
        // .replace("<svg", "<svg focusable='false' class='AsomIcon__Icon'")
        .replace('height="24"', `height="${this.iconSize}"`)
        .replace('width="24"', `width="${this.iconSize}"`)
        .replace('stroke="#4A5568"', 'stroke="currentColor"') // for heroicon
    }
  }
}
</script>
<style scoped>
.AsomIcon {
  @apply inline-block;
}

.AsomIcon__Icon {
  @apply fill-current
}

.AsomIcon--size-xs {
  @apply h-3.5 w-3.5
}

.AsomIcon--size-sm {
  @apply h-5 w-5
}

.AsomIcon--size-md {
  @apply h-6 w-6
}

.AsomIcon--size-lg {
  @apply h-12 w-12
}
</style>