<template>
  <div
    v-if="isShow"
    :class="[
      'AsomAlert',
      `AsomAlert--variant-${variant}`,
    ]"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <!-- Heroicon name: solid/information-circle -->
        <svg v-if="variant === 'info'" class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
        </svg>
        <!-- Heroicon name: solid/check-circle -->
        <svg v-if="variant === 'success'" class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
        </svg>
        <!-- Heroicon name: solid/x-circle -->
        <svg v-if="variant === 'error'" class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
        </svg>
        <!-- Heroicon name: solid/exclamation -->
        <svg v-if="variant === 'warning'" class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
      </div>
      <div class="px-3 w-full text-left break-words">
        <slot name="alert-title">
          <h3 class="AsomAlert__Title" v-if="alertTitle" v-dompurify-html="alertTitle" />
        </slot>
        <slot>
          <div v-if="htmlErrorMessage" v-dompurify-html="htmlErrorMessage" />
        </slot>
      </div>
      <div v-if="dismissible" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button type="button" @click="dismiss" class="AsomAlert__Close">
            <span class="sr-only">Dismiss</span>
            <!-- Heroicon name: solid/x -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import flatten from 'lodash.flatten';

export default {
  name: "AsomAlert",
  props: {
    variant: {
      type: String,
      default: 'info',
      validate: (value) => [ 'info', 'success', 'warning', 'error' ].includes(value)
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    errorMessage: {
      type: undefined,
    }
  },
  data() {
    return {
      isShow: true,
    }
  },
  computed: {
    alertTitle() {
      if (this.title)
        return this.title
      else if (this.serverErrors.length > 0)
        return this.$t('general.serverAlertTitle', { count: this.serverErrors.length});
      else if (this.errorMessage && typeof(this.errorMessage) === 'string')
        return this.errorMessage
      else if (this.errorMessage && this.errorMessage.message)
        return this.errorMessage.message
      return null;
    },
    serverErrors() {
      if (!this.errorMessage || typeof (this.errorMessage) != 'object')
        return [];

      let errors = [];
      try {
        let errorData = this.errorMessage && this.errorMessage.message || {};
        if (typeof(errorData) === "object") {
          if (typeof(errorData.message) !== 'string') {
            errors = flatten(Object.values(errorData));
          }
        }
      } catch (e) {
        console.log(e);
        errors = [];
      }
      return errors;
    },
    /**
     * @description customize server alert to display in a neater format
     */
    htmlErrorMessage() {
      if (this.serverErrors.length === 0)
        return null;

      let alertMessage = '';
      alertMessage +=  `<div class="mt-2 text-sm text-red-700">
        <ul class="list-disc pl-5 space-y-1">`;
      this.serverErrors.forEach(item => {
        alertMessage += `<li>${item}</li>`;
      });
      alertMessage += `</ul></div>`;
      return alertMessage;
    }
  },
  methods: {
    dismiss() {
      this.isShow = false
    }
  }
}
</script>
<style scoped>
.AsomAlert {
  @apply rounded-md p-4;
}

.AsomAlert--variant-info {
  @apply bg-blue-50;
}

.AsomAlert--variant-success {
  @apply bg-green-50
}

.AsomAlert--variant-error {
  @apply bg-red-50
}

.AsomAlert--variant-warning {
  @apply bg-yellow-50
}

.AsomAlert__Title {
  @apply text-sm font-medium;
}

.AsomAlert--variant-info .AsomAlert__Title {
  @apply text-blue-800;
}

.AsomAlert--variant-success .AsomAlert__Title {
  @apply text-green-800;
}

.AsomAlert--variant-error .AsomAlert__Title {
  @apply text-red-800;
}

.AsomAlert--variant-warning .AsomAlert__Title {
  @apply text-yellow-800;
}

.AsomAlert__Close {
  @apply inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600
}

.AsomAlert--variant-info .AsomAlert__Close {
  @apply bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600;
}

.AsomAlert--variant-success .AsomAlert__Close {
  @apply bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600;
}

.AsomAlert--variant-error .AsomAlert__Close {
  @apply bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600;
}

.AsomAlert--variant-warning .AsomAlert__Close {
  @apply bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600;
}

</style>