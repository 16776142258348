<template>
  <div :class="['AsomFormField', !label && 'AsomFormField--no-label', layout]">
    <div class="AsomFormField__LabelContainer">
      <slot name="label">
        <label v-if="label" class="AsomFormField__Label">
          {{label}}
          <span v-if="required" class="AsomFormField__Required">*</span>
        </label>
      </slot>
    </div>
    <div class="AsomFormField__FieldContainer">
      <slot></slot>
      <span class="AsomFormField__FieldError" v-if="state === 'error' && error">{{error}}</span>
      <slot name="description">
        <p v-if="description" class="AsomFormField__Description">{{description}}</p>
      </slot>
    </div>
  </div>
</template>

<script>
import inputStateMixin from '../input/inputStateMixin'

export default {
  name: "AsomFormField",
  mixins: [ inputStateMixin ],
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
    error: {
      type: String,
    },
    layout: {
      type: String,
      default: 'vertical' // 'vertical' | 'horizontal'
    }
  }
}
</script>
<style scoped lang="scss">
.AsomFormField {
  @apply sm:flex sm:items-start
}

.AsomFormField.vertical {
  @apply flex-col
}

@media (min-width: 769px) {
  .AsomFormField__LabelContainer {
    min-height: 40px;
  }
}

.AsomFormField--no-label.AsomFormField--no-label .AsomFormField__LabelContainer {
  display: none;
}

.AsomFormField__Label {
  @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-4 sm:flex-1
}

.AsomFormField__Required {
  @apply text-base font-semibold text-gray-700
}

.AsomFormField__Description {
  @apply text-sm text-gray-500
}

.AsomFormField__FieldContainer {
  @apply mt-1 sm:flex-auto space-y-2 items-start w-full
}

.AsomFormField__FieldError {
  @apply text-sm text-red-700
}
</style>
<style lang="scss">
.AsomFormField__FieldContainer {
  input, textarea, label, select, .multiselect {
    font-weight: theme("fontWeight.semibold");
  }
}
</style>