<template>
  <div class="AsomDropdown" :id="dropdownId">
    <slot name="toggle" :click="toggle">
      <asom-button class="AsomDropdown__ToggleButton" @click="toggle" :text="toggleText" />
    </slot>
    <transition name="AsomDropdown__Transition">
      <div
        :class="[
          'AsomDropdown__Content',
          `AsomDropdown__Content--position-${position}`,
          !isOpened && 'hidden'
        ]"
      >
        <slot :click="toggle"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { nanoid } from 'nanoid';
import AsomButton from '../button/AsomButton.vue'

export default {
  name: "AsomDropdown",
  components: { AsomButton },
  props: {
    toggleText: {
      type: String,
      default: 'Dropdown',
    },
    position: {
      type: String,
      default: 'bottom-left',
      validate: (value) => [ 'bottom-left', 'bottom-right', 'top-left', 'top-right' ].includes(value)
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    }
  },
  emits: [ 'toggle' ],
  data() {
    return {
      isOpened: false,
      dropdownId: nanoid(),
    }
  },
  methods: {
    toggle() {
      this.isOpened = !this.isOpened
      this.$emit('toggle', this.isOpened)
    },
    handleOutsideClick(event) {
      if (!this.isOpened)
        return;
      let dropdownEl = document.getElementById(this.dropdownId),
      targetEl = event.target; // clicked element      
      do {
        if(targetEl == dropdownEl) {
          return;
        }
        // Go up the DOM
        targetEl = targetEl.parentNode;
      } while (targetEl);
      // This is a click outside.
      this.isOpened = false
      this.$emit('toggle', false)
    }
  },
  mounted() {
    if (this.closeOnClickOutside) {
      document.addEventListener('click', this.handleOutsideClick);
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  watch: {
    closeOnClickOutside: function(newValue) {
      if (newValue) {
        document.addEventListener('click', this.handleOutsideClick);
      } else {
        document.removeEventListener('click', this.handleOutsideClick);
      }
    }
  }
}
</script>
<style scoped>
.AsomDropdown {
  @apply relative inline-block text-left
}
.AsomDropdown__Transition-enter-from {
  @apply transform opacity-0 scale-95
}

.AsomDropdown__Transition-enter-active {
  @apply transition ease-out duration-100
}

.AsomDropdown__Transition-enter-to {
  @apply transform opacity-100 scale-100
}

.AsomDropdown__Transition-leave-from {
  @apply transform opacity-100 scale-100
}

.AsomDropdown__Transition-leave-active {
  @apply transition ease-in duration-75
}

.AsomDropdown__Transition-leave-to {
  @apply transform opacity-0 scale-95
}

.AsomDropdown__Content {
  @apply absolute mt-2 min-w-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 origin-top-right 
}

.AsomDropdown__Content--position-bottom-right {
  @apply right-0
}

.AsomDropdown__Content--position-top-right {
  @apply right-0
}

.AsomDropdown__Content--position-bottom-left {
  @apply left-0
}

.AsomDropdown__Content--position-top-left {
  @apply left-0
}
</style>